

 let appliance_type_list= ['Television', 'Fan', 'Light(Bulb)', 'Sound-System', 'Fridge/Freezer', 'Air-Condition', 'Pumping-Machine', 'Games', 'Pressing-Iron', 'Laptops']

 let Television=[       {  group_name: 'LED Tv',
                                   group_items:[
                                             '15 Inches LED TV',
                                             '17 Inches LED TV',
                                             '19 Inches LED TV',
                                             '20 Inches LED TV',
                                             '21 Inches LED TV',
                                             '22 Inches LED TV',
                                             '24 Inches LED TV',
                                             '30 Inches LED TV',
                                             '32 Inches LED TV',
                                             '37 Inches LED TV',
                                             '42 Inches LED TV',
                                             '50 Inches LED TV',
                                    ],
                                },
                         {  group_name: 'LCD Tv',
                                       group_items:[
                                             '15 Inches LCD TV',
                                             '17 Inches LCD TV',
                                             '19 Inches LCD TV',
                                             '20 Inches LCD TV',
                                             '21 Inches LCD TV',
                                             '22 Inches LCD TV',
                                             '24 Inches LCD TV',
                                             '30 Inches LCD TV',
                                             '32 Inches LCD TV',
                                             '37 Inches LCD TV',
                                             '42 Inches LCD TV',
                                             '50 Inches LCD TV',
                                       ],
                                },           
                                           
                           {group_name: 'PLASMA TV',
                                       group_items:[
                                             '15 Inches PLASMA TV',
                                             '17 Inches PLASMA TV',
                                             '19 Inches PLASMA TV',
                                             '20 Inches PLASMA TV',
                                             '21 Inches PLASMA TV',
                                             '22 Inches PLASMA TV',
                                             '24 Inches PLASMA TV',
                                             '30 Inches PLASMA TV',
                                             '32 Inches PLASMA TV',
                                             '37 Inches PLASMA TV',
                                             '42 Inches PLASMA TV',
                                             '50 Inches PLASMA TV',
                                       ]
                               },
                                                  
                          ]


     let Fan=[       {  group_name: 'Ceiling Fan',
                          group_items:[
                                    'Normal Ceiling Fan',
                                    'Large Blade Size Ceiling Fan',
                                    
                           ],
                       },


                     {group_name: 'Rechargeable Fan',
                               group_items:[             
                                     'Rechargeable Standing Fan',             
                               ]
                      },

                     {  group_name: 'Standing Fan',
                              group_items:[
                                    'Normal Standing Fan',
                                    'Small Industrial Standing fan',
                                    'Large Industrial Standing fan',
                                    
                              ],
                       },           
                                  
                   {group_name: 'Table Fan',
                              group_items:[
                                    'Mini Table Fan',
                                    'Normal Table Fan',             
                              ]
                      },
                                         
                 ]


     let Light=[       {  group_name: 'Energy Saving(LED Lights)',
                             group_items:[
                                       '3 Watts LED',
                                       '5 Watts LED',
                                       '7 Watts LED',
                                       '12 Watts LED',
                                       '15 Watts LED',
                                       '26 Watts LED',
                                       '33 Watts LED',
                                       '45 Watts LED',
                                       '65 Watts LED',
                                      
                                       
                             ],
                  },
               
                 {group_name: 'Normal Bulbs',
                             group_items:[
                                   '40 Watts Bulb',
                                   '60 Watts Bulb',
                                   '100 Watts Bulb',
                                   '200 Watts Bulb',                                   
                             ]
                    },
                                
            ]           


  
    let Sound_System = [       {  group_name: '5.1 channel Home-Theatre',
                                        group_items:[
                                                'Mini Home-theatre (short Surround Speakers)',
                                                'Home-theatre (Tall Surround Speakers)',
                                                'High Performance Home-theatre (Tall Surround Speakers)',
                                             ],
                                 },
                                 {  group_name: '2 Speaker Decks',
                                        group_items:[
                                                'Mini Deck(Two speakers)',
                                                'Medium Deck(Two Speakers)',
                                                'High Performance Deck (Two or More Speakers)',
                                             ],
                                 },
                                     
                                                    
                ]                     


    let Fridge_Freezer = [       {  group_name: 'Fridge',
                                        group_items:[
                                                'Fridge (Bed-Side)',
                                                'Fridge (Table-Height)',
                                                'Fridge (Human-Height)',
                                                'Fridge (Double-door)',

                                             ],
                                 },
                                 {  group_name: 'Freezer',
                                        group_items:[
                                                '100 Litre Chest Freezer',
                                                '150 Litre Chest Freezer',
                                                '300 Litre Chest Freezer',
                                                '600 Litre Double Door Chest Freezer',                                               
                                               
                                             ],
                                 },
                                     
                                                    
                ]                 



    let Air_Condition = [  
        {  group_name: 'AC',
                group_items:[
                        '0.5 Horse-Power AC',
                        '1 Horse-Power AC',
                        '1.5 Horse-Power AC',
                        '2 Horse-Power AC',
                        '2.5 Horse-Power AC',
                        '3 Horse-Power AC',
                     ],
         },                                             
    ]    


    let Pumping_Machine = [  
        {  group_name: 'Pumping Machine',
                group_items:[
                        '1/2 Horse-Power Pumping Machine',
                        '3/4 Horse-Power Pumping Machine',
                        '1 Horse-Power Pumping Machine',
                        '1.5 Horse-Power Pumping Machine',
                        '3 Horse-Power Pumping Machine',
                        '5 Horse-Power Pumping Machine',
                     ],
         },                                             
    ] 


    let Games = [  
        {  group_name: 'Games',
                group_items:[
                        'Play Station 2' , 
                        'Play Station 3', 
                        'Play Station 4 Slim', 
                        'Play Station 4 ', 
                        'Xbox One X', 
                        'Xbox One S',              
                                 
                        'Xbox One',                       
                     ],
         },                                             
    ] 


    let Pressing_Iron = [  
        {  group_name: 'Pressing_Iron',
                group_items:[
                        'Small Pressing-Iron 800 Watt' , 
                        'Pressing-Iron 1200 Watt' ,
                        'Pressing-Iron 1500 Watt' ,
                        'Industrial Pressing-Iron 2000 Watt' ,                                           
                     ],
        },                                             
    ] 


    let Laptops = [  
        {  group_name: 'Laptops',
                group_items:[
                        'Mini Laptops 10.1 inch Screen 45 watt' , 
                        'Laptops 13 inch Screen 65 watt ' ,
                        'Laptops 15 inch Screen 90 watt ' ,                      
                        'Graphics/Animation/Gaming Laptops 120 watt' ,                                           
                     ],
        },                                             
    ] 


 let wattage=[{name:'15 Inches LED TV', watt:15},
                {name:'17 Inches LED TV', watt:18},
                {name: '19 Inches LED TV', watt:20},
                {name:'20 Inches LED TV', watt:24},
                {name:'21 Inches LED TV', watt:26},
                {name:'22 Inches LED TV', watt:30},
                {name:'24 Inches LED TV', watt:40},
                {name:'30 Inches LED TV', watt:50},
                {name:'32 Inches LED TV', watt:55},
                {name:'37 Inches LED TV', watt:60},
                {name:'42 Inches LED TV', watt:80},
                {name:'50 Inches LED TV', watt:100},

                {name:'15 Inches LCD TV', watt:18},
                {name:'17 Inches LCD TV', watt:20},
                {name:'19 Inches LCD TV', watt:22},
                {name:'20 Inches LCD TV', watt:26},
                {name:'21 Inches LCD TV', watt:30},
                {name:'22 Inches LCD TV', watt:40},
                {name:'24 Inches LCD TV', watt:50},
                {name:'30 Inches LCD TV', watt:60},
                {name:'32 Inches LCD TV', watt:70},
                {name:'37 Inches LCD TV', watt:80},
                {name:'42 Inches LCD TV', watt:120},
                {name:'50 Inches LCD TV', watt:150},
                
                {name:'15 Inches PLASMA TV', watt: 65},
                {name:'17 Inches PLASMA TV', watt: 75},
                {name:'19 Inches PLASMA TV', watt: 80},
                {name:'20 Inches PLASMA TV', watt: 90},
                {name:'21 Inches PLASMA TV', watt: 100},
                {name:'22 Inches PLASMA TV', watt: 110},
                {name:'24 Inches PLASMA TV', watt: 120},
                {name:'30 Inches PLASMA TV', watt: 150},
                {name:'32 Inches PLASMA TV', watt: 160},
                {name:'37 Inches PLASMA TV', watt: 180},
                {name:'42 Inches PLASMA TV', watt: 220},
                {name:'50 Inches PLASMA TV', watt: 300},

                {name:'Normal Ceiling Fan', watt: 60},
                {name:'Large Blade Size Ceiling Fan', watt: 80},
                {name:'Rechargeable Standing Fan', watt: 30},
                {name:'Normal Standing Fan', watt: 55},
                {name:'Small Industrial Standing fan', watt: 80},
                {name:'Large Industrial Standing fan', watt: 200},
                {name:'Mini Table Fan', watt: 20},
                {name:'Normal Table Fan', watt: 40},  

                {name:'3 Watts LED', watt: 3},  
                {name:'5 Watts LED', watt: 5},
                {name:'7 Watts LED', watt: 7},
                {name:'12 Watts LED', watt: 12},
                {name:'15 Watts LED', watt: 15},
                {name:'26 Watts LED', watt: 26},
                {name:'33 Watts LED', watt: 33},
                {name:'45 Watts LED', watt: 45},
                {name:'65 Watts LED', watt: 65},
                {name:'40 Watts Bulb', watt: 40},
                {name:'60 Watts Bulb', watt: 60},
                {name:'100 Watts Bulb', watt: 100},
                {name:'200 Watts Bulb', watt: 200}, 

                {name: 'Mini Home-theatre (short Surround Speakers)',watt:40},
                {name:'Home-theatre (Tall Surround Speakers)',watt:65},
                {name:'High Performance Home-theatre (Tall Surround Speakers)',watt:90},
                {name:'Mini Deck(Two speakers)', watt:60},
                {name:'Medium Deck(Two Speakers)',watt:90},
                {name:'High Performance Deck (Two or More Speakers)', watt:120},

                {name:'Fridge (Bed-Side)',  watt:60},
                {name:'Fridge (Table-Height)', watt:100},
                {name:'Fridge (Human-Height)', watt:200},
                {name:'Fridge (Double-door)', watt:300},
                {name:'100 Litre Chest Freezer', watt:150},
                {name: '150 Litre Chest Freezer', watt:200},
                {name:'300 Litre Chest Freezer', watt:300},
                {name:'600 Litre Double Door Chest Freezer',  watt:400},  
                
                {name:'0.5 Horse-Power AC',   watt:500}, 
                {name:'1 Horse-Power AC',   watt:1000},  
                {name:'1.5 Horse-Power AC',   watt:1500},                
                {name:'2 Horse-Power AC',   watt:2000}, 
                {name:'2.5 Horse-Power AC',   watt:2500}, 
                {name:'3 Horse-Power AC',   watt:3000},  

                {name:'1/2 Horse-Power Pumping Machine',  watt:500},
                {name:'3/4 Horse-Power Pumping Machine',  watt:750},
                {name:'1 Horse-Power Pumping Machine',  watt:1000},
                {name:'1.5 Horse-Power Pumping Machine',  watt:1500},
                {name:'3 Horse-Power Pumping Machine',  watt:3000},
                {name:'5 Horse-Power Pumping Machine',  watt:5000},

                {name:'Play Station 2' , watt:45},
                {name:'Play Station 3', watt:150}, 
                {name:'Play Station 4 Slim', watt:80},
                {name:'Play Station 4 ', watt:130},
                {name:'Xbox One X', watt:120},
                {name:'Xbox One S', watt:70},                                     
                {name:'Xbox One', watt:100},              
              
                {name:'Small Pressing-Iron 800 Watt' , watt:800}, 
                {name:'Pressing-Iron 1200 Watt' , watt:1200},
                {name:'Pressing-Iron 1500 Watt' ,  watt:1500},
                {name:'Industrial Pressing-Iron 2000 Watt' ,  watt:2000},

                {name:'Mini Laptops 10.1 inch Screen 45 watt' ,  watt:45},
                {name:'Laptops 13 inch Screen 65 watt ' , watt:65},
                {name:'Laptops 15 inch Screen 90 watt ' ,    watt:90}   ,                
                {name:'Graphics/Animation/Gaming Laptops 120 watt' ,   watt:120}  
            ]
           



 export {
             wattage, appliance_type_list, Television, Fan, Light, Sound_System, Fridge_Freezer, Air_Condition, Pumping_Machine,
             Games, Pressing_Iron, Laptops,

         };