// App.js
import React, { Component } from "react";
import { Route, Redirect, withRouter, Link, Switch, BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";

import axios from "axios";

//React-Toastify is a better alternative for react-notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  wattage,
  appliance_type_list,
  Television,
  Fan,
  Light,
  Sound_System,
  Fridge_Freezer,
  Air_Condition,
  Pumping_Machine,
  Games,
  Pressing_Iron,
  Laptops,
} from "./extra_script";

const base_url = "http://localhost:8000/api/";

//const base_url= "https://backend.learnershub.co.za/api/";

toast.configure({
  autoClose: 3000,
  draggable: false,
  //etc you get the idea
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcome_page: true,
    };
  }

  remove_welcome_page = (control) => {
    this.setState({ welcome_page: control });
  };

  render() {
    let content = <Welcome data={this.remove_welcome_page.bind(this)} />;
    if (this.state.welcome_page == false) {
      content = <Calculate />;
    }

    return <div>{content}</div>;
  }
}

class Welcome extends Component {
  render() {
    return (
      <div>
        <div style={{ width: "70%", margin: "5% 0 0 10%" }}>
          <img src="ola.jpg" style={{ margin: "0 5% 5% 10%", width: "200px", float: "left" }} />
          <p>Hello, My Name is Olaoluwa Akinshipe.</p>
          <p>
            Due to erratic power suply in nigeria, it has become a neccesity for me to build this tool to help people in the little way that I can. As the first
            step in solving their power problem, most people just want the answer to the question "How much will it cost me to get an Inverter?"
          </p>
          <p>Well, the truth is that, the cost of installing alternative power varies for every situation due to different energy needs."</p>
          <p>So I decided to build this tool to answer this question based on what you want to power with the inverter.</p>
          <p>
            This tool will estimate the quantity and price of equipments (e.g Inverter, batteries, solar pannels (if needed) e.t.c) that you need for the
            project. It will also show you the total estimated cost for the project.
          </p>
          <p></p>
          <p style={{ color: "red" }}> All you have to do is;</p>
          <ul style={{ color: "red" }}>
            <li> Input in the appliances or electronics you want to power with the inverter.</li>
            <li> Input in how many hours during the day you need the appliance on.</li>
            <li> Input in how many hours at night you need the appliance on.</li>
          </ul>
          <p style={{ color: "red" }}>And Voila! You have an estimate for your project</p>
          <p>
            {" "}
            I hope you enjoy using this tool and I hope i will be able to make up time to keep it updated with current prices. If you have any problem,
            questions, you can contact me through email
          </p>
          Merci! avez un bon moment ici.
          <button style={{ width: "40%", minWidth: "200px" }} class="button" type="button" onClick={(_) => this.props.data(false)}>
            Get Your Estimate Here
          </button>
        </div>

        <div style={{ marginTop: "1%", float: "right" }}>
          <hr />
          <img src="time.jpg" style={{ marginLeft: "10%", width: "10%" }} />
          <span style={{ marginLeft: "1%", fontSize: "20px" }}>
            <b>Prices were Last Updated At Dec 2020</b>
          </span>
        </div>
      </div>
    );
  }
}

class Calculate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_appliance_type: "",
      current_appliance: "",
      appliance_list: [],
      table_list: [],
      open_modal: false,
      current_quotation: "",
    };
  }

  validate_table_values = () => {
    let table_list = this.state.table_list;
    for (let x = 0; x < table_list.length; x++) {
      if (table_list[x].day_use + table_list[x].night_use == 0) {
        alert(
          "Both day and night hours usage of " +
            table_list[x].name +
            " cannot be 0 (zero). The appliance must be used for atleast one hour either during the day or at night. " +
            "Please adjust the usage hours appriopriately or remove the appliance from the table"
        );
        return false;
      }

      if (Number(this.state.table_list[x].night_use) + Number(this.state.table_list[x].day_use) > 24) {
        alert(
          "The addition of the day usage hours and night usage hours of " +
            this.state.table_list[x].name +
            " cannot be more than 24 since there are only 24 hours in a day. Please adjust appriorpriately"
        );
        return false;
      }
    }

    return true;
  };

  show_quotation = (event) => {
    let control = event.target.id;
    if (this.validate_table_values()) {
      this.open_or_close_modal();
      this.setState({ current_quotation: "" });
      setTimeout((_) => this.setState({ current_quotation: control }), 1000);
    }

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-1014884288");

    function gtag_report_conversion(url) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };

      gtag("event", "conversion", {
        send_to: "AW-1014884288/Z5-uCJr409YBEMDP9-MD",
        event_callback: callback,
      });
      return false;
    }

    gtag_report_conversion();
  };

  show_alternative_quotation = (control) => {
    if (this.validate_table_values()) {
      this.setState({ current_quotation: "" });
      setTimeout((_) => this.setState({ current_quotation: control }), 1000);
    }
  };

  open_or_close_modal = () => {
    this.setState({ open_modal: !this.state.open_modal });
  };

  thousands_separators = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  add_appliance_to_list = () => {
    if (this.state.current_appliance == "") {
      alert("Please select an appliance type to add");
      return;
    }
    if (this.state.appliance_list.includes(this.state.current_appliance)) {
      alert(
        "You have already added " + this.state.current_appliance + " to the list. " + "You can increase its quantity under its quantity column in the table"
      );
      return;
    } else {
      let current_appliance_list = this.state.appliance_list;
      current_appliance_list.push(this.state.current_appliance);
      this.setState({ appliance_list: current_appliance_list });

      for (let x = 0; x < wattage.length; x++) {
        if (this.state.current_appliance == wattage[x].name) {
          let current_object = {};
          current_object.name = this.state.current_appliance;
          current_object.watt = wattage[x].watt;
          current_object.quantity = 1;
          current_object.day_use = 0;
          current_object.night_use = 0;
          let current_list = this.state.table_list;
          current_list.push(current_object);
          this.setState({ table_list: current_list });
        }
      }
    }
  };

  change_table_values = (event, item) => {
    let key = this.state.table_list.indexOf(item);
    if (event.target.id == "quantity") {
      let current_list = this.state.table_list;
      current_list[key].quantity = event.target.value;
      this.setState({ table_list: current_list });
    }
    if (event.target.id == "day_use") {
      let current_list = this.state.table_list;
      current_list[key].day_use = event.target.value;
      this.setState({ table_list: current_list });
    }
    if (event.target.id == "night_use") {
      let current_list = this.state.table_list;
      current_list[key].night_use = event.target.value;
      this.setState({ table_list: current_list });
    }

    if (Number(this.state.table_list[key].night_use) + Number(this.state.table_list[key].day_use) > 24) {
      alert(
        "The addition of the day usage hours and night usage hours of " +
          this.state.table_list[key].name +
          " cannot be more than 24 since there are only 24 hours in a day. Please adjust appriorpriately"
      );
    }
  };

  remove_from_list = (item) => {
    let key = this.state.table_list.indexOf(item);
    let key2 = this.state.appliance_list.indexOf(item.name);

    let current_table_list = this.state.table_list;
    current_table_list.splice(key, 1);
    this.setState({ table_list: current_table_list });

    let current_appliance_list = this.state.appliance_list;
    current_appliance_list.splice(key2, 1);
    this.setState({ appliance_list: current_appliance_list });
  };

  calculate_inverter_alone_table_values = () => {
    let appliance_details = null;
    let inverter_quotation = null;

    if (this.state.appliance_list.length !== 0) {
      let total_night_time_power = 0;
      let total_day_time_power = 0;
      let total_appliance_combine_wattage = 0;

      for (let x = 0; x < this.state.table_list.length; x++) {
        let current_appliance = this.state.table_list;

        let current_appliance_day_time_power = current_appliance[x].watt * current_appliance[x].quantity * current_appliance[x].day_use;
        total_day_time_power = total_day_time_power + current_appliance_day_time_power;

        let current_appliance_night_time_power = current_appliance[x].watt * current_appliance[x].quantity * current_appliance[x].night_use;
        total_night_time_power = total_night_time_power + current_appliance_night_time_power;

        let current_appliance_watt = current_appliance[x].watt * current_appliance[x].quantity;
        total_appliance_combine_wattage = total_appliance_combine_wattage + current_appliance_watt;
      }
      let case1_needed_battery = (total_day_time_power + total_night_time_power) / 14 / 100;
      let minimum_wattage = total_appliance_combine_wattage * 8;
      let calculated_wattage = total_day_time_power + total_night_time_power;
      if (minimum_wattage > calculated_wattage) {
        case1_needed_battery = minimum_wattage / 1440;
      }

      if (case1_needed_battery == 0) {
        case1_needed_battery = 0;
      } else if (case1_needed_battery < 1) {
        case1_needed_battery = 1;
      } else if (case1_needed_battery < 1) {
        case1_needed_battery = 1;
      } else if (case1_needed_battery < 2) {
        case1_needed_battery = 2;
      } else if (case1_needed_battery < 4) {
        case1_needed_battery = 4;
      } else {
        let x = case1_needed_battery / 4;
        x = Math.ceil(x);
        case1_needed_battery = x * 4;
      }

      let price_of_inverter = 0;
      let inverter = "";
      if ((total_appliance_combine_wattage * 2.5) / 1000 < 0.8) {
        price_of_inverter = 40000;
        inverter = 1;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 1.2) {
        price_of_inverter = 85000;
        inverter = 1.5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 2) {
        price_of_inverter = 140000;
        inverter = 2;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 3.5) {
        price_of_inverter = 190000;
        inverter = 3.5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 5) {
        price_of_inverter = 270000;
        inverter = 5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 7.5) {
        price_of_inverter = 420000;
        inverter = 7.5;
      } else {
        inverter = (total_appliance_combine_wattage * 2.5) / 1000;

        price_of_inverter = ((total_appliance_combine_wattage * 3) / 1000 - 7.5) * 110000 + 420000;
      }
      let price_per_battery = 90000;
      let installation_fees = (case1_needed_battery * price_per_battery + price_of_inverter) * 0.1;
      let case1_total_amount = installation_fees + case1_needed_battery * price_per_battery + price_of_inverter;

      appliance_details = (
        <div style={{ margin: "auto", width: "97%", marginTop: "5%" }}>
          <h4>Currently Added Appliance Table</h4>
          <table>
            <tr>
              <th>S/N</th>
              <th>Appliance</th>
              <th>Wattage Per Appliance</th>
              <th>Quantity</th>

              <th>Day Time Use (hours)</th>
              <th>Night Time Use(hours)</th>

              <th>Total Day Time Power Consumption (Watt)</th>
              <th>Total Night Time Power(Watt) Consumption</th>
            </tr>

            {this.state.table_list.map((item, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {item.name}
                  <button type="button" onClick={() => this.remove_from_list(item)}>
                    Remove
                  </button>
                </td>
                <td>{item.watt}</td>
                <td>
                  <input
                    type="number"
                    id="quantity"
                    name="quantity"
                    min="1"
                    max="500"
                    value={item.quantity}
                    onChange={(event) => this.change_table_values(event, item)}
                  />
                </td>

                <td>
                  <input
                    type="number"
                    id="day_use"
                    name="day_use"
                    min="0"
                    max="12"
                    value={item.day_use}
                    onChange={(event) => this.change_table_values(event, item)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    id="night_use"
                    name="night_use"
                    min="0"
                    max="12"
                    value={item.night_use}
                    onChange={(event) => this.change_table_values(event, item)}
                  />
                </td>
                <td>{item.watt * item.day_use * item.quantity}</td>
                <td>{item.watt * item.night_use * item.quantity}</td>
              </tr>
            ))}

            <tr>
              <th>Total</th>
              <th></th>
              <th></th>
              <th></th>

              <th></th>
              <th></th>

              <th>{total_day_time_power}</th>
              <th> {total_night_time_power}</th>
            </tr>
          </table>
        </div>
      );

      inverter_quotation = (
        <div style={{ marginTop: "3%" }}>
          <p style={{ marginTop: "10px", color: "red" }}>
            <b>
              Important Information
              <br />
              1. Since you have opted for the non-solar charging package. You must have an alternative power supply to recharge your batteries after usage.
              Power supply could be in the form of Public power supply (PHCN) or a Generator. <br />
              2. This quotation is designed to give you about 14 hours daily of the energy requirements you inputed.
              <br />
              3. This quotation is based on the fact that you will have about 10 hours daily of alternative power supply to charge your batteries. If you do not
              have upto 10 hours of alternative power supply, external chargers will have to be installed for you (Don't worry external chargers are not
              expensive).
              <br />
              4. It is extremely important to read the comment column of each item in the table, this could help you in understanding the actions you can take
              to reduce your total cost of installation.
            </b>
          </p>

          <h3 style={{ marginTop: "2%" }}>Inverter and Battery System (Non-Solar Charging)</h3>

          <table>
            <colgroup>
              <col></col>
              <col></col>
              <col></col>
              <col></col>
              <col style={{ width: "30%" }} />
            </colgroup>
            <tr>
              <th>Description of Items</th>
              <th>Quantity Needed</th>
              <th>Price/Unit(Naira)</th>

              <th>Total(Naira)</th>
              <th>Comment</th>
            </tr>
            <tr>
              <th>200AH 12volt Deep-Cycle Battery</th>
              <th>{case1_needed_battery}</th>
              <th>{this.thousands_separators(price_per_battery)}</th>

              <th>{this.thousands_separators(case1_needed_battery * price_per_battery)}</th>
              <th>
                The batteries you need depends on the wattage of your appliance, and the number of hours that they will be on for. Reducing any of this two
                factors should impact your needed battery quantity. Also, changing some of your appliances (e.g changing your bulbs from 15w bulbs to 7 watt
                bulbs ) should significantly help reduce the amount of batteries you need too.
              </th>
            </tr>
            <tr>
              <th>{inverter}KVA Pure Sine-wave Inverter</th>
              <th>1</th>
              <th> {this.thousands_separators(price_of_inverter)} </th>

              <th>{this.thousands_separators(price_of_inverter)}</th>
              <th>
                Inverter size is dependent only of the total wattage of the appliance you want to power. The number of hours the appliances will send on does
                not affect inverter size. So, if you want to reduce inverter cost, consider removing appliances that are not essential.
              </th>
            </tr>
            <tr>
              <th>
                Installation Fees and Accesories
                <p>(Accessories may include but not limited to cables, cable-logs, battery-rack, external chargers, and so on).</p>{" "}
              </th>
              <th>Lump-sum</th>
              <th>Lump-sum</th>

              <th>{this.thousands_separators(installation_fees)} </th>
              <th>
                This amount can vary depending on the site conditions. Installation fees are subject to the amount of accesories (e.g external charger, lenght
                of cables, size of cables, e.t.c )which is dependent on site conditions. In summary installation fees may be less than the quoted value or may
                be more. Take this value as an average value.
              </th>
            </tr>
            <tr>
              <th>Total</th>
              <th></th>
              <th></th>

              <th style={{ color: "red" }}>
                <b>{this.thousands_separators(case1_total_amount)}</b>
              </th>
              <th>
                This is the total of all the sub-sections above. To reduce your total installation cost, consider implementing the advice under the comment
                column for each item above.
              </th>
            </tr>
          </table>
          <div class="quotation_button" style={{ marginTop: "3%" }}>
            From experience most clients always wants to compare both inverter alone quotations and also the quotation with solar charging. So, I have decided
            to also prepare the quotation with the solar charging.
            <div>
              <button class="button" id="inverter" type="button" onClick={(_) => this.show_alternative_quotation("solar")}>
                View Quotation With Solar Charging Here
              </button>
            </div>
          </div>
        </div>
      );
    }

    return [appliance_details, inverter_quotation];
  };
  calculate_inverter_and_solar_table_values = () => {
    let solar_quotation = null;

    if (this.state.appliance_list.length !== 0) {
      let total_night_time_power = 0;
      let total_day_time_power = 0;
      let total_appliance_combine_wattage = 0;

      for (let x = 0; x < this.state.table_list.length; x++) {
        let current_appliance = this.state.table_list;

        let current_appliance_day_time_power = current_appliance[x].watt * current_appliance[x].quantity * current_appliance[x].day_use;
        total_day_time_power = total_day_time_power + current_appliance_day_time_power;

        let current_appliance_night_time_power = current_appliance[x].watt * current_appliance[x].quantity * current_appliance[x].night_use;
        total_night_time_power = total_night_time_power + current_appliance_night_time_power;

        let current_appliance_watt = current_appliance[x].watt * current_appliance[x].quantity;
        total_appliance_combine_wattage = total_appliance_combine_wattage + current_appliance_watt;
      }

      let needed_pannels = ((total_night_time_power + total_day_time_power) / 200 / 6) * 1.25;
      needed_pannels = Math.ceil(needed_pannels);
      let cost_of_a_pannel = 36000;
      let cost_of_charge_controller = 30000 + (total_night_time_power + total_day_time_power) * 7.5;
      let total_pannel_cost = cost_of_a_pannel * needed_pannels;
      ////
      let cost_of_a_battery = 90000;
      let minimum_needed_battery = (total_appliance_combine_wattage * 4) / 1440;
      let case1_needed_battery = total_night_time_power / 1440;
      if (minimum_needed_battery > case1_needed_battery) {
        case1_needed_battery = minimum_needed_battery;
      }

      if (case1_needed_battery == 0) {
        case1_needed_battery = 0;
      } else if (case1_needed_battery < 1) {
        case1_needed_battery = 1;
      } else if (case1_needed_battery < 1) {
        case1_needed_battery = 1;
      } else if (case1_needed_battery < 2) {
        case1_needed_battery = 2;
      } else if (case1_needed_battery < 4) {
        case1_needed_battery = 4;
      } else {
        let x = case1_needed_battery / 4;
        x = Math.ceil(x);
        case1_needed_battery = x * 4;
      }

      let price_of_inverter = 0;
      let inverter = "";
      if ((total_appliance_combine_wattage * 2.5) / 1000 < 0.8) {
        price_of_inverter = 40000;
        inverter = 1;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 1.2) {
        price_of_inverter = 85000;
        inverter = 1.5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 2) {
        price_of_inverter = 140000;
        inverter = 2;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 3.5) {
        price_of_inverter = 190000;
        inverter = 3.5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 5) {
        price_of_inverter = 270000;
        inverter = 5;
      } else if ((total_appliance_combine_wattage * 2.5) / 1000 < 7.5) {
        price_of_inverter = 420000;
        inverter = 7.5;
      } else {
        inverter = (total_appliance_combine_wattage * 2.5) / 1000;

        price_of_inverter = ((total_appliance_combine_wattage * 3) / 1000 - 7.5) * 110000 + 420000;
      }

      let installation_fees = (case1_needed_battery * cost_of_a_battery + price_of_inverter + total_pannel_cost + cost_of_charge_controller) * 0.15;
      let case1_total_amount = installation_fees + case1_needed_battery * cost_of_a_battery + price_of_inverter + total_pannel_cost + cost_of_charge_controller;

      solar_quotation = (
        <div style={{ marginTop: "3%" }}>
          <p style={{ marginTop: "10px", color: "red" }}>
            <b>
              Important Notice!
              <br />
              1. This quotation is designed to give you 24 hours energy supply, so far you stick to the appliances and their hourly use that you inputed when
              generating this quotation.
              <br />
              2. It is extremely important to read the comment column of each item in the table. This could help in explaining to you actions you can take to
              reduce your cost of installation.
            </b>
          </p>

          <h3 style={{ marginTop: "2%" }}>Solar Charging, Inverter and Battery System</h3>

          <table>
            <colgroup>
              <col></col>
              <col></col>
              <col></col>
              <col></col>
              <col style={{ width: "30%" }} />
            </colgroup>

            <tr>
              <th>Description of Items</th>
              <th>Quantity Needed</th>
              <th>Price/Unit(Naira)</th>

              <th>Total(Naira)</th>
              <th>Comment</th>
            </tr>

            <tr>
              <th>200 Watt Mono-Crystalline Solar Pannels</th>
              <th>{this.thousands_separators(needed_pannels)}</th>
              <th>{this.thousands_separators(cost_of_a_pannel)}</th>

              <th>{this.thousands_separators(total_pannel_cost)}</th>
              <th>
                The quantity of solar pannels is based on the the fact that, you do not have other alternative source of charging your batteries. if do have
                other sources, you may not need this many solar pannels.
              </th>
            </tr>
            <tr>
              <th>200AH 12volt Deep Cycle Battery</th>
              <th>{this.thousands_separators(case1_needed_battery)}</th>
              <th>{this.thousands_separators(cost_of_a_battery)}</th>

              <th>{this.thousands_separators(case1_needed_battery * cost_of_a_battery)}</th>
              <th>
                The quantity of batteries you need are majorly based on the appliaces you plan to power at night. Consider powering heavy load appliances (e.g
                freezers, pumping machine e.t.c) during the day alone. This will significantly reduce both your installation cost and you recurrents cost on
                replacing batteries. Also changing some of your appliances (e.g changing your bulbs from 15w bulbs to 7 watt bulbs ) should significantly help
                ro reduce needed batteries. By so doing, your total installation cost will be reduced also.
              </th>
            </tr>
            <tr>
              <th>{inverter}KVA Pure Sinewave Inverter</th>
              <th>1</th>
              <th> {this.thousands_separators(price_of_inverter)} </th>

              <th>{this.thousands_separators(price_of_inverter)}</th>
              <th>
                Inverter size is dependent only on the total wattage of the appliance you want to power. Number of hours the appliances are on do not affect
                inverter size. To reduce inverter cost consider removing non-essential appliances.
              </th>
            </tr>
            <tr>
              <th>MPPT Charge Controller</th>
              <th>Lump-sum</th>
              <th> Lump-sum </th>

              <th>{this.thousands_separators(cost_of_charge_controller)}</th>
              <th>
                Charge Controllers are what convert the energy from your solar pannels to safe energy of the batteries. The capacity of the needed charge
                controller is dependent on the amount of solar pannels you need. Reducing your power needs should reduce the cost of your charge controller.
              </th>
            </tr>
            <tr>
              <th>
                Installation Fees and Accesories<p>(Accessories include but not limited to cables, cable-logs, battery-rack, and so on)</p>{" "}
              </th>
              <th>Lump-sum</th>
              <th>Lump-sum</th>

              <th>{this.thousands_separators(installation_fees)} </th>
              <th>
                This amount can vary depending on the site conditions. Installation fees are subject to the amount of accesories (e.g external charger, lenght
                of cables, size of cables) which is dependent on site conditions. In summary installation fees may be less than the quoted value or may be more.
                Take this value as an average value.
              </th>
            </tr>
            <tr>
              <th>Total</th>
              <th></th>
              <th></th>

              <th style={{ color: "red" }}>{this.thousands_separators(case1_total_amount)}</th>
              <th></th>
            </tr>
          </table>

          <div class="quotation_button" style={{ marginTop: "3%" }}>
            From experience, clients always wants to compare both quotations of solar charging and non-solar charging. So, I have decided to also prepare the
            quotation with the non-solar charging for you,
            <div>
              <button class="button" id="inverter" type="button" onClick={(_) => this.show_alternative_quotation("inverter")}>
                Non-Solar Charging Inverter Quotation
              </button>
            </div>
          </div>
        </div>
      );
    }

    return solar_quotation;
  };

  render() {
    let current_appliance_type = null;
    let current_appliance_list = null;

    if (this.state.current_appliance_type === "Television") {
      current_appliance_list = Television;
    }
    if (this.state.current_appliance_type === "Fan") {
      current_appliance_list = Fan;
    }
    if (this.state.current_appliance_type === "Light(Bulb)") {
      current_appliance_list = Light;
    }
    if (this.state.current_appliance_type === "Sound-System") {
      current_appliance_list = Sound_System;
    }
    if (this.state.current_appliance_type === "Fridge/Freezer") {
      current_appliance_list = Fridge_Freezer;
    }
    if (this.state.current_appliance_type === "Air-Condition") {
      current_appliance_list = Air_Condition;
    }
    if (this.state.current_appliance_type === "Pumping-Machine") {
      current_appliance_list = Pumping_Machine;
    }
    if (this.state.current_appliance_type === "Games") {
      current_appliance_list = Games;
    }
    if (this.state.current_appliance_type === "Pressing-Iron") {
      current_appliance_list = Pressing_Iron;
    }
    if (this.state.current_appliance_type === "Laptops") {
      current_appliance_list = Laptops;
    }

    if (this.state.current_appliance_type !== "") {
      current_appliance_type = (
        <div style={{ marginTop: "3%" }}>
          <b> Select the Type of {this.state.current_appliance_type} Here : </b>
          <select
            id="year"
            className="questionform"
            value={this.state.current_appliance}
            onChange={(event) => {
              this.setState({ current_appliance: event.target.value });
            }}
          >
            <option value="">Select Appliance</option>
            {current_appliance_list.map((item) => (
              <optgroup label={item.group_name}>
                {item.group_items.map((item2) => (
                  <option value={item2}>{item2}</option>
                ))}
              </optgroup>
            ))}
          </select>
          <button style={{ marginTop: "5%" }} class="button" type="button" onClick={this.add_appliance_to_list}>
            Add Appliance
          </button>
        </div>
      );
    }

    let value = this.calculate_inverter_alone_table_values();
    let appliance_details = value[0];
    let inverter_quotation = value[1];
    let solar_quotation = this.calculate_inverter_and_solar_table_values();
    let current_quotation = (
      <div style={{ width: "200px", margin: "auto" }}>
        <img src="loading.gif" />
      </div>
    );

    if (this.state.current_quotation == "solar") {
      current_quotation = solar_quotation;
    }
    if (this.state.current_quotation == "inverter") {
      current_quotation = inverter_quotation;
    }
    let quotation_buttons = (
      <div style={{ margin: "auto", width: "50%", marginTop: "7.5%" }}>
        <h6>Your List is Currently Empty. Please Select Your Appliances Above and Click The "Add Appliance Button". Thank You</h6>
        <img src="empty.png" style={{ marginLeft: "30%", width: "20%" }} />
      </div>
    );

    if (this.state.appliance_list.length != 0) {
      quotation_buttons = (
        <div class="quotation_button" style={{ marginTop: "3%" }}>
          <div>
            <button class="button" id="inverter" type="button" onClick={this.show_quotation}>
              Get Inverter with Quotation (Non-Solar Charging)
            </button>
          </div>
          <div>
            <button class="button" id="solar" type="button" onClick={this.show_quotation}>
              Get Inverter Quotation with Solar-Charging
            </button>
          </div>
        </div>
      );
    }

    let appliance_summary = (
      <div>
        Hurray! Your customised material and price quotation is ready. You can view it below.
        <p>Just to be sure your power needs are :</p>
        {this.state.table_list.map((item, index) => (
          <p>
            <b>{index + 1}. </b> {item.quantity} unit(s) of {item.name} to be used for {item.day_use} hour(s) during the day and {item.night_use} hour(s) at
            night."
          </p>
        ))}
      </div>
    );

    return (
      <div>
        <div style={{ margin: "auto", width: "70%", marginTop: "10%" }}>
          <b>Add The Appliances You Want to Power Here :</b>

          <select
            id="appliance"
            className="questionform"
            value={this.state.current_appliance_type}
            onChange={(event) => {
              this.setState({ current_appliance_type: event.target.value });
              this.setState({ current_appliance: "" });
            }}
          >
            <option value="">Select Appliance Type</option>
            {appliance_type_list.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>

          {current_appliance_type}
        </div>
        <br />
        {appliance_details}

        {quotation_buttons}

        <div style={{ marginTop: "150px", float: "right" }}>
          <hr />
          <img src="time.jpg" style={{ marginLeft: "10%", width: "10%" }} />
          <span style={{ marginLeft: "1%", fontSize: "20px" }}>
            <b>Prices were Last Updated At Dec 2020</b>
          </span>
        </div>

        <Modal isOpen={this.state.open_modal} contentLabel="Minimal Modal Example">
          <button style={{ float: "right" }} type="button" onClick={this.open_or_close_modal}>
            Exit
          </button>

          {appliance_summary}
          {current_quotation}

          <div style={{ marginTop: "10%", float: "right" }}>
            <hr />
            <img src="time.jpg" style={{ marginLeft: "10%", width: "10%" }} />
            <span style={{ marginLeft: "1%", fontSize: "20px" }}>
              <b>Prices were Last Updated At Dec 2020</b>
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}

export default App;
